.acc2{
    padding-bottom: 60px;
    max-width: 1550px;
    margin: 0 auto;
    margin-top: 90px;
}
.acc2,
.aci2,
.acb2 {
    margin-bottom: 5px; /* Ajusta el valor según el espacio deseado */
    background-color: transparent;
}
.acb2{
    font-size: 24px;
}
