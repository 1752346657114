.containerR {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fabc6a05;
    width: 30vw;
    height: 20vw;
    margin-top: 15%;
    padding-bottom: 15%;
    margin-left: 35%;
  }
  
  .topboxR {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
  }
  .middleboxR {
    margin-top: 0%;
    text-align: center;
    line-height: 1.6; /* Ajusta el espaciado entre líneas del texto */
    width: 180%;
  }

/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .containerR {
    width: 80vw;
    height: auto;
    margin-top: 20%;
    padding: 10% 0;
    margin-left: 10%; /* Ajusta el margen izquierdo en dispositivos móviles */
  }

  .topboxR {
    font-size: 30px;
  }

  .middleboxR {
    width: 100%;
    font-size: 16px;
  }
}