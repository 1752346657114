.header2 {
  position: relative;
  overflow: hidden;
}

.image-container {
  position: relative;
  width: 100%;
  height: 35vh; /* Adjust the height for the image container */
}

.header-image {
  width: 100%; /* Set the width to 100% to stretch the image from edge to edge */
  height: 50%; /* Set the height to 100% to maintain the aspect ratio */
  object-fit: cover;
}

.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%; /* Adjust the height to match the image container's height */
  background-color: rgba(0, 0, 0, 0.5); /* Change the value of the last parameter to adjust the opacity */
}

.content-header2 {
  position: absolute;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-header2{
  font-size: 23px;
  color: white;
  font-weight: bold;
}

/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .image-container {
    height: 25vh; /* Ajusta la altura del contenedor de la imagen para dispositivos móviles */
  }
  .text-header2 {
    font-size: 15px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    padding-top: 30%; /* Mueve el texto más abajo en dispositivos móviles */
  }
}