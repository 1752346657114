
/**********FONDO*******************/
body {
  font-family: 'Noto Serif', serif, sans-serif !important;
  background: linear-gradient(90deg, white 25%, orange);
  background-size: 100% 100vh; /* Ajusta el tamaño del fondo */
  position: relative; /* Permite al seudoelemento estar posicionado en relación a esto */
}

/**********FONDO*******************/

/*********NAVBAR**********/
.navbar {
  justify-content: center;
  font-size: 1.1vw;
  font-weight: bold;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 10px;
}

.nav-link {
  text-decoration: none;
  color: black !important;
}
.navbar-collapse{
  padding-right: 5%;
}

.dropdown-menu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 0px; /* Ajusta el ancho del cuadro del menú */
  max-height: 200px; /* Ajusta la altura máxima del cuadro del menú */
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  padding: 5px 10px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #ffa348; /* Cambia el color de fondo al pasar el cursor por encima */
  color: black; /* Cambia el color del texto al pasar el cursor por encima */
}

.navbar-nav {
  width: 100%;
  justify-content: center;
}

.navbar-nav .nav-link {
  margin-left: 20px; /* Ajusta el valor según tus necesidades */
}

.navbar-brand {
  margin-left: 20px;
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .navbar {
    font-size: 4vw; /* Ajusta el tamaño de fuente para dispositivos móviles */
  }

  .nav-list {
    flex-direction: column; /* Cambia la dirección para apilar los elementos */
    align-items: center; /* Centra los elementos verticalmente */
  }

  .nav-item {
    margin-right: 0; /* Quita el margen derecho para apilar los elementos */
    margin-bottom: 10px; /* Agrega un margen inferior entre elementos */
  }

  .navbar-nav .nav-link {
    margin: 0; /* Quita el margen izquierdo y derecho para dispositivos móviles */
  }

  .navbar-brand {
    margin: 0; /* Quita los márgenes para dispositivos móviles */
  }

  .dropdown-menu {
    position: static; /* Cambia la posición para que el menú desplegable se muestre correctamente */
    width: auto; /* Quita el ancho fijo para que el menú tome su ancho natural */
    max-height: none; /* Quita la altura máxima para que el menú desplegable tenga su altura natural */
    display: block !important; /* Asegura que el menú desplegable se muestre */
  }
}
/*********NAVBAR**********/

/****************HEADER****************/
.header {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.5); /* Cambia el valor del último parámetro para ajustar la opacidad */
}

.content {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.text {
  color: white;
  font-size: 3.5vw; /* Ajusta el tamaño de fuente utilizando vw */
}

.text2 {
  color: white;
  font-size: 1.3vw; /* Ajusta el tamaño de fuente utilizando vw */
  margin-top: 3vw; /* Ajusta el margen superior utilizando vw */
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  z-index: 999;
}

.social-icons {
  position: absolute; /* Ajustamos la posición en la parte superior */
  top: 30vw; /* Ajustamos la posición en el lado derecho utilizando vh */
  right: 37.3vw; /* Ajustamos la posición en el lado derecho utilizando vw */
  z-index: 999;
  padding-top: 0; /* Eliminamos el padding top para que no haya espacio extra */
  order: 12;
}

.social-icons a {
  margin-right: 1vw; /* Ajusta el margen derecho utilizando vw */
  color: white;
  font-size: 0vw; /* Ajusta el tamaño de fuente utilizando vw */
  text-decoration: none;
}

.social-icons a img {
  width: 3vw; /* Ajusta el tamaño de la imagen utilizando vw */
  height: 3vw; /* Ajusta el tamaño de la imagen utilizando vw */
  margin: 0 1vw 0.5vw; /* Ajusta los márgenes utilizando vw */
  transition: transform 0.1s ease-in-out;
  border-radius: 1vw; /* Ajusta el radio del borde utilizando vw */
}

.social-icons a:hover img {
  transform: scale(1.3);
}

/* ... Estilos anteriores ... */

@media (max-width: 768px) {
  .text {
    font-size: 7vw;
    margin-bottom: 5vw;
  }

  .text2 {
    font-size: 5vw;
    margin-top: 1.5vw;
  }

  .content {
    top: 40%; /* Ajustamos la posición vertical en el centro */
  }

  .social-icons {
    position: relative;
    bottom: auto;
    top: -33%; /* Ajustamos la posición en la parte superior */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%; /* Ajustamos el ancho al 100% para centrar los íconos */
    z-index: 999; /* Aseguramos que estén en primer plano */
  }

  .social-icons a {
    margin: 0 1vw;
    display: inline-block;
  }

  .social-icons a img {
    width: 10vw;
    height: 10vw;
    margin: 0 1vw 1vw;
    border-radius: 2vw;
  }
}

/****************HEADER****************/

/*********BODY*********/
.body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-content-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7%;
}

.right-content-nosotros {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
}

.image {
  width: 100%;
  height: auto;
  max-width: 600px;
  border-radius: 3vw;
}

.H2 {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.paragraph {
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
}


.body2{
  padding-top: 7%;
  flex: auto;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.additional-paragraph1{ 
  flex: auto;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-left: 270px;
  text-align: center;
  font-size: 1.2vw;
}
.additional-paragraph1 p{
  padding-right: 120px;
  text-align: justify;
}
.additional-paragraph2{ 
  flex: auto;
  margin-top: 0px;
  padding-top: 10px;
  text-align: right;
  padding-right: 270px;
  text-align: center;
  margin: 0;
  font-size: 1.2vw;
}
.additional-paragraph2 p{
  padding-left: 120px;
  text-align: justify;
}

.centered-paragraph {
  text-align: center;
  padding-top: 30px; /* Ajusta la distancia entre los párrafos */
}


.H31{ /*MISION*/
  text-align: left;
  font-size: 40px;
  font-weight: bold;

}
.H32{ /*VISION*/
  padding-left: 110px;
  text-align: left;
  font-size: 40px;
  font-weight: bold;
}

.body3{
  padding-left: 3%;
}

.H33{/*VALORES TITULO*/
  font-size: 40px;
  font-weight: bold;
  padding-right: 60px;
}
.valores{
  text-align: left;
  padding-left: 44.5%;
  font-size: 1.2vw;
  list-style-position: inside;
}

@media (max-width: 768px) {
  .body {
    flex-direction: column;
    align-items: center;
  }

  .left-content-image,
  .right-content-nosotros {
    flex: 1;
    padding: 0;
  }

  .image {
    border-radius: 5px;
    max-width: 90%;
    margin-bottom: 20px;
  }

  .H2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .paragraph {
    font-size: 16px;
    margin-top: 10px;
  }

  .body2 {
    padding-top: 3%;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .additional-paragraph1,
  .additional-paragraph2 {
    padding: 0;
    text-align: center;
    font-size: 16px;
  }

  .additional-paragraph1 p,
  .additional-paragraph2 p {
    padding: 0;
  }

  .body3 {
    padding-left: 0;
    margin-top: 20px;
  }

  .H31,
  .H32,
  .H33 {
    font-size: 24px;
    padding: 0;
    text-align: center;
  }

  .valores {
    padding-left: 0;
    font-size: 16px;
    text-align: center;
  }
}

/*********BODY*********/

/************FOOTER**************/
.footer {
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: -10px;
  padding-bottom: 20px;
  margin-top: 10%
}

.footer p {
  margin: 0;
}

.left-content,
.right-content {
  flex: 1;
  text-align: center;
  font-size: 18px;
}

.left-content{ 
  padding-top: 10px;
  padding-left: 270px;
  text-align: center;
}

.left-content p {
  text-decoration: underline;
}

.right-content{ 
  margin-top: 0px;
  padding-top: 10px;
  text-align: right;
  padding-right: 270px;
  text-align: center;
  margin: 0;
}
.right-content p{ 
  font-size: 17px;
  object-fit: contain;

}

.centered-content {
  margin-top: 230px;
  align-self: flex-start;
  text-decoration: underline;
  font-size: 15px;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .left-content,
  .right-content {
    flex: 1;
    margin: 10px;
    padding: 0;
  }

  .left-content p,
  .right-content p {
    font-size: 14px;
  }

  .centered-content {
    text-align: center;
    font-size: 14px;
    order: 3; /* Cambia el orden para que quede al final */
    padding-top: 0px;
  }
}
/************FOOTER**************/

