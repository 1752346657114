/* Contenedor de la galería */
.gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  /* Tarjeta individual */
  .card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Imagen de la tarjeta */
  .card-img {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  /* Contenido de la tarjeta */
  .card-content {
    padding: 16px;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }
  
  .card-text {
    font-size: 0.95rem;
    color: #666;
    margin-bottom: 16px;
  }
  
  /* Botón */
  .card-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 0.95rem;
    font-weight: bold;
    color: #fff;
    background-color: #f0ad4e;
    border-radius: 6px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .card-btn:hover {
    background-color: #ec971f;
  }