/* Estilos para el Footer */
.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #2c3e50; /* Fondo elegante */
    color: #ecf0f1; /* Texto claro */
    padding: 20px 40px;
    flex-wrap: wrap; /* Permite adaptarse a pantallas pequeñas */
    gap: 20px;
  }
  
  .footer-section {
    flex: 1;
    min-width: 250px;
  }
  
  .footer-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #f39c12; /* Color destacado */
  }
  
  .footer-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #bdc3c7;
  }
  
  .footer-text a {
    color: #1abc9c; /* Color de los enlaces */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-text a:hover {
    color: #16a085;
  }
  
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-section {
      min-width: auto;
    }
  }