.gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .card-content {
    padding: 10px;
  }
  
  .card-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .card-btn {
    margin-top: 10px;
  }

  .carousel-inner img {
    object-fit: cover; /* Asegura que la imagen llene el contenedor manteniendo su proporción */
    height: 500px;     /* Ajusta la altura del carrusel */
    max-height: 100%;  /* Evita que la imagen sea más alta que el contenedor */
  }

  .video-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
   .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 2rem;
    color: #ffc107;
    cursor: pointer;
  }
  
  .video-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
  
  .video-iframe {
    flex: 1 1 calc(45% - 15px);
    max-width: calc(45% - 15px);
    height: 200px;
    border-radius: 8px;
  }


  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
    text-align: center;
  }
  
  .carousel-inner iframe {
    border-radius: 8px;
  }

  .carousel-control-prev .carousel-control-next{
    color: #ffc107;
  }