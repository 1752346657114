/* donaciones.css */
body {
  font-family: "Roboto-Condensed", sans-serif;
  font-size: 18px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
  gap: 40px;
  padding: 30px 0;
}

.section {
  width: 100%;
  text-align: center;
}

.paypal-amount-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Espacio entre los botones */
  margin-bottom: 20px; /* Espacio debajo de los botones */
}

.paypal-amount-buttons .btn {
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px; /* Bordes redondeados */
  transition: background-color 0.3s ease;
}

.paypal-amount-buttons .btn-selected {
  background-color: #ffd700;
  color: #000;
  border: 1px solid #ffd700;
}

.paypal-amount-buttons .btn:hover {
  background-color: #f0f0f0;
}

.top-box h1,
.middle-box p,
.paypal-section h2,
.bottom-boxes h2,
.down-box h2 {
  margin-bottom: 15px;
}

.middle-box p {
  font-size: 20px;
  line-height: 1.5;
}

.paypalBox {
  margin: 20px auto;
}

.pp-image-paypal {
  max-width: 200px;
  height: auto;
}
.paypal-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto; /* Para dar espacio adicional */
}

.paypal-section {
  text-align: center; /* Centra el texto y los elementos en la sección */
  padding: 20px;
}

.paypal-button-container .btn {
  font-size: 18px;
  padding: 12px 25px;
}

.pp-section .pp-image {
  max-width: 180px;
  height: auto;
}

.bottom-boxes {
  text-align: center;
}

.btn-selected {
  background-color: #ffd700;
  color: #000;
  border: 1px solid #ffd700;
}

.box-container {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}

.box-container .btn {
  font-size: 18px;
  padding: 12px 18px;
  min-width: 100px;
}

.down-box .ctaPA {
  max-width: 600px;
  height: auto;
  margin-top: 20px;
}


.down-box p {
  font-size: 1.2rem; /* Aumentar texto de la cuenta bancaria */
}

@media (max-width: 768px) {
  .container {
    width: 95%;
  }

  .paypal-button-container .btn,
  .box-container .btn {
    font-size: 16px;
    padding: 10px 15px;
  }

  .pp-image-paypal,
  .pp-section .pp-image,
  .down-box .ctaPA {
    max-width: 90%;
  }
}