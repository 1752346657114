.form-container {
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  height: 870px; /* Establecer la altura al 100% de la altura visible del viewport si deseas centrar verticalmente respecto a la pantalla */
}
/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  .form-container {
    height: auto; /* Cambiar la altura a auto en dispositivos móviles */
    padding: 20px; /* Agregar un padding para mejorar el espaciado */
  }
}

