/* carrusel.css */

/* Ajusta el tamaño del carrusel */
.carousel {
    max-width: 50%; /* Ajusta el ancho máximo según tus necesidades */
    margin:  auto; /* Centra el carrusel horizontalmente */
  }
  
  /* Asegura que las imágenes del carrusel se ajusten al tamaño del carrusel */
.carousel-inner img {
    max-width: 100%;
    height: auto;
    border-radius: 0%; /* Ajusta el valor según el nivel de redondeo que desees */
}
.carousel-item img {
  height: 600px; /* Set the desired height for the images /
  object-fit: cover; / Maintain the aspect ratio and fill the container */
  width: 100%;
  object-fit: contain;
}

/* Ajusta la posición de las flechas del carrusel */
.carousel-control-prev,
.carousel-control-next {
  transform: translateY(-50%); /* Ajusta la posición vertical */
}
.carousel-control-prev{
transform: translateX(-100%);
}
.carousel-control-next{
transform: translateX(100%);
}

/* Ajusta la posición de los indicadores (barritas) */
.carousel-indicators {
  bottom: 30px; /* Ajusta la distancia desde abajo */
  margin-bottom: 5%;
}

/* Estilos para el contenedor del botón */
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0%;
    margin-bottom: 5%;
  }
  .button-container button{
    font-size: 20px;
    margin-top: 8%;
  }
  
/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .carousel {
    max-width: 75%; /* Reduzco el ancho máximo para dispositivos móviles */
    margin: 0 auto; /* Centra el carrusel horizontalmente */
  }
  .carousel .carousel-arrow {
    display: block; /* Muestra las flechas de cambio de foto */
  }
  .button-container {
    flex-direction: column; /* Cambia la dirección en dispositivos móviles */
    margin-bottom: 5%; /* Reduzco el margen inferior para más espacio */
  }
  .button-container button {
    margin: 5px 0; /* Añade espacio vertical entre los botones */
  }
}
