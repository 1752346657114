.articulos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titulo-articulos {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.articulos-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.articulo-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  width: 280px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.articulo-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.articulo-imagen {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 12px;
}

.articulo-info {
  padding: 8px;
}

.articulo-titulo {
  font-size: 1.2rem;
  font-weight: bold;
  color: #444;
  margin-bottom: 8px;
}

.articulo-autor {
  font-size: 1rem;
  color: #777;
}

.articulo-edad {
  font-style: italic;
  color: #999;
}

.articulo-fecha {
  font-size: 0.9rem;
  color: #bbb;
  margin-top: 8px;
}

/* Modal principal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

/* Contenido del modal */
.modal-content {
  background: #fff;
  width: 90%;
  max-width: 600px;
  max-height: 80%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrar todo el contenido */
}

/* Imagen del modal (tamaño reducido) */
.modal-imagen {
  width: 50%; /* Reducir el tamaño de la imagen */
  height: auto;
  border-radius: 15px;
  margin-bottom: 15px;
}

/* Cuerpo del modal con desplazamiento */
.modal-body {
  max-height: 350px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 10px;
  flex-grow: 1; /* Asegurar que el cuerpo ocupe el espacio restante */
}

/* Título del modal */
.modal-titulo {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 18px;
}

/* Botón de cierre */
.cerrar-modal {
  position: absolute;
  top: 2px;
  right: 10px;
  background: #d32f2f;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 10; /* Asegurarnos de que el botón esté encima */
}

/* Responsivo para pantallas pequeñas */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    max-height: 90%;
  }

  .modal-imagen {
    width: 80%; /* Imagen más pequeña en pantallas móviles */
  }
}
