/* Contenedor principal */
.body-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px;
}

/* Sección Nosotros y Timeline en la misma fila */
.nosotros-timeline-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
}

.nosotros-card {
  width: 48%; /* Ajusta para que ambos ocupen la mitad del ancho */
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.image-container {
  flex: 1;
  padding-right: 20px;
}

.image {
  width: 100%;
  max-width: 250px; /* Ajusta el tamaño de la imagen */
  height: auto;
  border-radius: 8px;
}

.nosotros-text {
  flex: 2;
}

.H2 {
  font-size: 36px;
  font-weight: bold;
  color: #2c3e50;
}

.paragraph {
  font-size: 16px;
  color: #7f8c8d;
  line-height: 1.6;
}

/* Sección Timeline */
.timeline-section {
  width: 48%; /* Ajustado para ocupar la otra mitad */
}

.timeline-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.timeline-item .timeline-icon {
  position: absolute;
  left: -40px;
  background-color: #f39c12;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
}

.timeline-item .timeline-content {
  margin-left: 80px;
  background-color: #ecf0f1;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.timeline-item .timeline-content h3 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 15px;
}

.timeline-item .timeline-content p {
  font-size: 16px;
  color: #7f8c8d;
  line-height: 1.6;
}

.fas {
  font-size: 30px;
}

/* Sección Nuestros Valores */
.valores-section {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.valores-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.valores-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 30%;
  text-align: center;
}

.valores-card h3 {
  font-size: 24px;
  color: #2c3e50;
  margin-top: 15px;
}

.valores-card p {
  font-size: 16px;
  color: #7f8c8d;
  line-height: 1.6;
}

.valores-card i {
  font-size: 40px;
  color: #f39c12;
}

/* Responsividad */
@media (max-width: 768px) {
  .body-container {
    flex-direction: column;
  }

  .nosotros-timeline-section {
    flex-direction: column;
  }

  .nosotros-card, .timeline-section {
    width: 100%;
  }

  .valores-cards {
    flex-direction: column;
    align-items: center;
  }

  .valores-card {
    width: 80%;
    margin-bottom: 30px;
  }
}
