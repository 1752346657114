.header3 {
    position: relative;
    overflow: hidden;
  }
  
  .image-container {
    position: relative;
    width: 100%;
    height: 35vh; /* Adjust the height for the image container */
  }
  
  .header-image {
    width: 100%; /* Set the width to 100% to stretch the image from edge to edge */
    height: 50%; /* Set the height to 100% to maintain the aspect ratio */
    object-fit: cover;
  }
  
  .overlay3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%; /* Adjust the height to match the image container's height */
    background-color: rgba(0, 0, 0, 0.5); /* Change the value of the last parameter to adjust the opacity */
  }
  
  .content-header3 {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .text-header3{
    font-size: 24px;
    color: white;
    font-weight: bold;
  }
  
/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .content-header3 {
    top: 29%; /* Ajusta la posición vertical */
  }

  .text-header3 {
    font-size: 17px; /* Ajusta el tamaño de fuente */
  }
}